#preRegister{
    background: url('../assets/pre-register/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    padding: 0 20vw;
    width: 100%;
}

#preRegister > div{
    width: 100%;
    min-height: 80vh;
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.6);
    padding-top: 14vh;

    display: flex;
    align-items: center;
    flex-direction: column;

    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: white;
    font-size: 2.5vh;
}

#form-container{
    width: 100%;
    height: 48vh;
    position: relative;

    margin: 1.25vh 0;
}

#form-container > form{
    width: 100%;
    height: 100%;
    /* background: red; */
    position: absolute;
    top: 0;
}


.form-section{
    width: 100%;
    height: 100%;
    padding: 0 3em;
    /* background-color: blue; */
}

#register-bottom{
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.75vh;
    padding: 0 2vw;
}

#register-bottom > div{
    cursor: pointer;
}

.left-right, .inp{
    margin: 1.5vh 0;
    padding: 8px 15px;
    /* border-radius: 8px; */
    border-bottom: 1px solid white;
    position: relative;
}

.left-right{
    display: flex;
    justify-content: space-evenly;
    height: fit-content;
}

.left-right > label{
    cursor: pointer;
    width: 30%;
    padding: .35em .25em;
    text-align: center;
    border: 1px solid white;
    border-radius: 5px;
    transition: background-color 0.2s ease;
    user-select: none;
}

.left-right > label:hover{
     background-color: #444;
}

.left-right > input[type="radio"]:checked + label{
    color: white;
    background: #666;
}

.left-right > span{
    position: absolute;
    left: 10px;
    pointer-events: none;
    /* background: #fff; */
    transform-origin: left;
    transform: translateY(-20px) scale(0.65);
    padding: 0 7px;
}

.left-right > input[type="radio"]{
    display: none;
}

.inp{
    user-select: none;
    display: flex;
    align-items: center;
}

.inp > * , .left-right > *{
    font-size: 14pt;
}

.inp > span{
    position: absolute;
    left: 10px;
    top: 8px;
    pointer-events: none;
    /* background: #fff; */
    transition: transform 0.2s ease;
    transform-origin: left;
    padding: 0 7px;
}

.inp > input, .inp > textarea, select, .left-right > input{
    border: none;
    padding: 0;
    width: 100%;
    outline: none;
    resize: none;
    background: transparent;
    color: white
}

.inp > input:focus + span, .inp:hover > input + span, .inp > input:not(:placeholder-shown) + span,
.inp > textarea:focus + span, .inp:hover > textarea + span, .inp > textarea:not(:placeholder-shown) + span{
    transform: translateY(-20px) scale(0.65);
}

.inp > p{
    border-right: 1px solid white;
    padding-right: 3px;
    margin-right: 3px;
}

#flag{
    width: 22px;
    height: 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    margin-right: 5px;
}

#flag > div{
    height: 50%;
    width: 100%;
}

#flag > div:first-child{
    background: red;
}

#flag > div:last-child{
    background: white;
}

#submit-btn{
    display: flex;
    justify-content: space-between;
}

#submit-btn > *{
    color: white;
}

#submit-btn > input[type="submit"]{
    border: 1px solid white;
    padding: 8px 11px;
    background: transparent;
    font-size: 12pt;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}

#submit-btn > input[type="submit"]:hover{
    background-color: #444;
}

#submit-btn > input[type="submit"]:active{
    background-color: #666;
    color:white;
}

#phoneNumber > span{
    transform: translateY(-20px) scale(0.65);
}

input[type="date"]{
    color: white;
}

option{
    color: white;
    background: black;
}

#userTerm{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

#userTerm > label{
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox{
    display: flex;
    align-items: center;
    width: 2.6vh;
    height: 2.6vh;
    border-radius: 3px;
    padding: 0.3vh;
    border: 0.25vh solid white;
    background-color: transparent;
    margin-right: .6vw;
}

.checkbox > div{
    display: none;
    width: 1.5vh;
    height: 1.5vh;
    border-radius: 3px;
    border: 1px solid white;
    background-color: white;
}

input[type="checkbox"]:checked + label > .checkbox > div{
    display: block;
}