#footer{
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: 100vw;
    height: 8vh;
    background-color: #000;
    color: white;
    padding: .5em;

    display: flex;
    flex-direction: column;
    align-items: center;
}

#footer-text{
    display: flex;
}

#footer-text > div{
    cursor: pointer;
}

#footer-text > span{
    margin: 0 1em;
}

#footer > hr{
    width: 60vw;
}

#footer > *{
    margin: 2px 0;
}

#footer > div{
    font-size: .9vw;
}

#footer > div:last-child{
    font-size: .7vw;
}