#moving-background-container{
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

#home-moving-background{
    pointer-events: none;
    position: relative;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.moving-bg{
    position: absolute;
    min-width: 115vw;
    z-index: -1;
    opacity: 0;
    transition: transform 8s linear , opacity 0.5s ease-in;
    
    background-color: rgba(0, 0, 0, 0.4);
}

.moving-bg-active{
    opacity: 1;
}

#header a{
    text-decoration: none;
    color: white;
}

.preload *{
    transition: none !important;
}

#content{
    width: 100vw;
    min-height: 80vh;
    overflow-y: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

#header{
    width: 100vw;
    height: 12vh;

    background-color: #000;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 1;
}

#header > img{
    margin-top: 2.125em;
    height: 16vh;
    z-index: 1;
}

#nav *{
    outline: none;
}

#nav{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 2;
    
    display: flex;
    justify-content: center;
}

#nav-container{
    pointer-events: all;
    position: sticky;
    padding: .5em;
    margin-top: 19vh;
    top: .5em;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    background-color: #000;
    color: white;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 1vw;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.5);
}

#nav-container > span{
    height: 3vh;
    width: 1px;
    background-color: #fff;
}

.nav-triangle{
    border-top: 5px solid transparent;
    border-left: 10px solid white;
    border-bottom: 5px solid transparent;
    margin: 0 2px;

    transition: transform 0.35s ease;
}

.nav-item{
    cursor: pointer;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item > span{
    border-bottom: 1px solid transparent;
    transition: all .15s ease-in-out;
}

.nav-item > span{
    margin: 0 2px;
}

.nav-item:hover > .nav-triangle{
    transform: rotate(90deg);
}

.nav-item:hover > span{
    border-bottom: 1px solid white;
}

.dropdown{
    position: relative;
}

.dropdown-container{
    pointer-events: none;
    position: absolute;
    top: calc(100%);
    left: 0;
    overflow: hidden;
}

.dropdown-mover{
    pointer-events: all;
    width: max-content;
    z-index: 3;

    background-color: #000;
    padding: 5px 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 0px 5px 2px rgba(0, 0, 0, 0.5);
    /* top: -200px; */
    transform: translateY(-100%);
    transition: transform .35s ease-in-out;
    display: flex;
    flex-direction: column;
    /* position: absolute; */
}

.dropdown:hover .dropdown-mover{
    transform: translateY(0%);
    /* top: 0px; */
}

.dropdown-item{
    margin: 10px 0;
}

.dropdown-item > span{
    z-index: 3;
    border-bottom: 1px solid transparent;
    transition: all .15s ease-in-out;
}

.dropdown-item:hover > span{
    border-bottom: 1px solid white;
}

@media only screen and (max-width: 800px) {
    #header > img{
        height: 8vh;
    }
    #nav{
        position: static;
        height: fit-content !important;
        box-shadow: none;
    }
    #nav-container{
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        width: 100%;
        border: none;
        border-radius: 0;
    }
}