@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
  /* background-color: #0A7250; */
}

body{
  overflow-y: overlay;
  background-color: #e5e5e5;
}

.dark body{
  background-color: #171717 !important;
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border: solid 5px transparent;
  background-clip: padding-box;
  border-radius: 9999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* .dark body{
  background-color: #0A7250;
} */