.lore{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center !important;

    min-height: 60vh;
}

.lore-triangle-top{
    border-bottom: 5vh solid white;
    border-left: 100vw solid rgba(0, 0, 0, 0.6);
}

.lore-header{
    background-color: white;
    height: .5vh;
}

.lore-triangle-bottom{
    border-top: 5vh solid white;
    border-right: 100vw solid rgba(0, 0, 0, 0.6);
}

.lore-content{
    color: white;
    padding: 3vh 31vh 15vh 31vh;
    font-size: 2.5vh;
    min-height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.6);
    user-select: none;
}

.lore-content > h1{
    margin: 3vh 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.lore-content > div{
    width: 100%;
    padding: 2vh 4vh 5vh 3vh;
    border-radius: 10px;
    text-align: justify;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#birthofgoddess{
    background: url('../assets/lore/birthofgoddess.jpg');
}

#contient{
    background: url('../assets/lore/continent.png');
}

#monsteroutbreak{
    background: url('../assets/lore/monsteroutbreak.jpg');
}

#ellady{
    background: url('../assets/lore/ellady.jpg');
}

#elmasters{
    background: url('../assets/lore/elmasters.jpg');
}

#festival{
    background: url('../assets/lore/festival.jpg');
}

#eksupulosion{
    background: url('../assets/lore/eksupulosion.jpg');
}