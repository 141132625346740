#galleries{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: center !important;
    background: url('../assets/galleries/background.png');

    padding: 0 20vw;
}

#galleries > div{
    width: 100%;
    min-height: 80vh;
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 8px 5px rgba(0, 0, 0, 0.6);
    padding-top: 16vh;

    display: flex;
    align-items: center;
    flex-direction: column
}

#gallery-outer{
    border: 5px solid rgba(0, 0, 0, 0.6);
    border-radius: 5px;
}

#gallery-large{
    background-color: #000;
    width: 40vw;
    height: 42vh;
    overflow: hidden;
}

#gallery-large > *{
    width: 100%;
    height: 100%;
    border-bottom: 1vh solid #111;
}

#gallery-navigator{
    width: 40vw;
    height: 15vh;
    background-color: #111;
    cursor: pointer;
    overflow: hidden;
}

#gallery-navigator:active{
    cursor: grabbing;
}

#gallery-navigator-mover{
    position: relative;
    height: 100%;
    transition: all 0.2s;
    will-change: transform;
    user-select: none;
    white-space: nowrap;
}

.gallery-item{
    display: inline-block;
    width: calc(40vw / 3.2);
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
}

.gallery-item > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    transition: all 0.2s ease;
}

.item-video:hover > div{
    background-color: rgba(0, 0, 0, 0.75);
}

.item-video > div > div:first-child{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-video:hover .circle{
    opacity: 1;
}

.circle{
    position: absolute;
    padding: 10%;
    border: .5vh solid white;
    border-radius: 50%;
    transition: all 0.2s ease;
    opacity: 0;
}

.gallery-item:first-child > div{
    justify-content: flex-end;
}

.gallery-item:last-child > div{
    justify-content: flex-start;
}

.item-left-triangle{
    border-right: .3vw solid #111;
    border-top: 15vh solid transparent;
}

.item-right-triangle{
    border-left: .3vw solid #111;
    border-bottom: 15vh solid transparent;
}

#vid1{
    background: url('../assets/galleries/vid1.png');
    background-size: cover;
    background-position: center;
}

#img1{
    background: url('../assets/galleries/img1.jpg');
}

#img2{
    background: url('../assets/galleries/img2.jpg');
}

#img3{
    background: url('../assets/galleries/img3.jpg');
}

#img4{
    background: url('../assets/galleries/img4.jpg');
}

.triangle{
    width: 0;
    height: 0;
    border-top: 1vh solid transparent;
    border-left: 2vh solid white;
    border-bottom: 1vh solid transparent;
}