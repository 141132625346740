#characters{
    display: flex;
    min-height: 80vh;
    background-color: rgb(90, 90, 90);
    overflow: hidden;
}

#characters > div{
    width: calc(100vw / 4);
}

.character{
    position: relative;
    perspective: 1000px;
}

.character:hover > .character-container{
    z-index: 1;
    transform: rotateY(180deg);
}

.character-container{
    height: 100%;
    transition: all 0.2s ease;
    backface-visibility: hidden;
}

.character-name{
    height: 30%;
    text-align: center;
    flex-direction: column;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;
    padding: 0 8vw;
    padding-top: 16vh;
    font-size: 2.5vh;
}

.character-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 70%;
    padding: 2vh 1vw;
}

.character-image > img{
    width: 100%;
}

.elesis{
    background: rgb(255, 81, 0);
}

.ara{
    background-color: rgb(43, 43, 43);
}

.add{
    background: rgb(171, 19, 209);
}

.luciel{
    background: rgb(0, 119, 255);
}

.character:hover > .character-description{
    transform: rotateY(360deg);
}

.character-description{
    position: absolute;
    top: 0;
    left: 0;

    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: white;

    transform: rotateY(180deg);
    transition: all 0.2s ease;
    backface-visibility: hidden;
    height: 100%;
    padding: 2vh 2vw;

    display: flex;
    align-items: center;
    text-align: center;
    font-size: 1.9vh;
}

.character-container, .character-description{
    pointer-events: none;
}