#side-image{
    position: absolute;
    left: 0;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: space-between;
}

#side-image img{
    height: 100%;
}

#side-image > img:last-child{
    transform: translateX(-3vw);
}

#slider-top{
    width: 100%;
    height: 3vh;
    background-color: rgba(50, 50, 50, 0.85);
    display: flex;
    align-items: center;
    border-bottom: .5vh solid black;

    padding: 0 10px;
}

#slider-top > div:last-child{
    display: flex;
}

#slider-top > div:last-child > div:first-child{
    background-color: #f00;
}

#slider-top > div:last-child > div:last-child{
    background-color: #0f0;
}

.dot{
    width: .8vw;
    height: .8vw;
    border-radius: 50%;
    margin: 0 .2em;

    background-color: yellow;
}

#slider-container{
    width: 40vw;
    margin-top: 5vh;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.6);
}

#slider-container > div{
    display: flex;
    transition: transform 1s ease-in;
}

#slider-container > div > img{
    width: 40vw;
}

#slider-nav{
    border-top: .5vh solid black;
    width: 100%;
    height: 2.35vh;
    background-color: rgba(50, 50, 50, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
}

#slider-nav > div{
    display: flex;
}

.circle-dot{
    width: .5vw;
    height: .5vw;
    border-radius: 50%;
    border: 1px solid rgba(133, 255, 240, .7);
    box-shadow: 0 0 3px 1px rgba(133, 255, 240, .7);
    background-color: rgba(133, 255, 240, .7);
    margin: 0 5px;

    transition: all .8s linear;
}

.circle-dot-active{
    background-color: #fff;
    border: 1px solid white;
    box-shadow: 0 0 3px 1px #fff;
}